"use client";

import Link from "next/link";
import {Link as ChakraLink, Stack, Heading} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, SUPPORT_OPTIONS} from "../footer.constants";

const FooterSupport = () => {
  return (
    <Stack spacing={6}>
      <Heading color="gray.900" variant={HEADING_VARIANTS.SUBTITLE3}>
        {FOOTER_CONSTANTS.SUPPORT}
      </Heading>
      <Stack>
        {SUPPORT_OPTIONS.map((option) => {
          const {name, path} = option;
          return (
            <ChakraLink
              as={Link}
              href={path}
              key={path}
              _hover={{color: "primary.200", textDecoration: "underline"}}
            >
              {name}
            </ChakraLink>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FooterSupport;
