"use client";

import Image from "next/image";
import Link from "next/link";
import {Box, Heading, HStack, Icon, Stack} from "@chakra-ui/react";
import logo from "public/brand/logo-horizontal.png";
import onemindLogo from "public/brand/onemind-logo.png";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, SOCIAL_OPTIONS} from "../footer.constants";

const FooterBranding = () => {
  return (
    <Stack alignItems={{base: "center", sm: "start"}} spacing={4}>
      <Stack>
        <Box>
          <Image alt="CloudMyDC" src={logo} width={200} />
          <Heading fontWeight={400} variant={HEADING_VARIANTS.SUBTITLE3}>
            {FOOTER_CONSTANTS.BRAND_TEXT}
          </Heading>
        </Box>
        <HStack as={Link} href={FOOTER_CONSTANTS.ONEMIND_PATH} target="_blank">
          <Image alt="Onemind Services" src={onemindLogo} width={40} />
          <Heading fontWeight={400} variant={HEADING_VARIANTS.SUBTITLE3}>
            {FOOTER_CONSTANTS.ONEMIND_ENTERPRISE}
          </Heading>
        </HStack>
      </Stack>
      <HStack spacing={6} justifyContent={"center"}>
        {SOCIAL_OPTIONS.map((option, index) => {
          const {icon: SocialIcon, path} = option;
          return (
            <Icon
              as={SocialIcon}
              cursor="pointer"
              key={index}
              onClick={() => window.open(path, "_blank")}
              _hover={{color: "primary.200"}}
            />
          );
        })}
      </HStack>
    </Stack>
  );
};

export default FooterBranding;
