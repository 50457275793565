"use client";

import {Fragment} from "react";
import {Analytics} from "@vercel/analytics/react";
import Script from "next/script";
import "styles/app.scss";
import {ChakraProvider, ColorModeScript} from "@chakra-ui/react";
import {theme} from "@/theme/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Providers({children}: {children: React.ReactNode}) {
  return (
    <Fragment>
      <Script
        async
        id="hs-script-loader"
        src="//js-na1.hs-scripts.com/43951417.js"
        strategy="afterInteractive"
        type="text/javascript"
      ></Script>
      <Script id="cookie-consent" type="text/javascript">
        {`
          var _iub = _iub || [];
          _iub.csConfiguration = {
            "enableCcpa":true,
            "countryDetection":true,
            "whitelabel":false,
            "lang":"en",
            "siteId":3179205,
            "cookiePolicyId":68148006,
            "enableTcf":true,
            floatingPreferencesButtonDisplay:"anchored-center-right",
              "banner":{
              "acceptButtonDisplay":true,
              "customizeButtonDisplay":true,
              "position":"float-bottom-left",
              "zIndex":99999998
            }
          };
        `}
      </Script>
      <Script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></Script>
      <Script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"
      ></Script>
      <Script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></Script>
      <Script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      ></Script>

      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            duration: 6000,
            isClosable: true,
          },
        }}
      >
        <ColorModeScript initialColorMode={"light"} />
        {children}
      </ChakraProvider>
      <Analytics />
    </Fragment>
  );
}
