import {defineStyle, defineStyleConfig} from "@chakra-ui/react";

const brand = defineStyle({
  bg: "primary.200",
  color: "white",
  _hover: {
    bg: "primary.200",
    color: "white",
  },
});

const primary = defineStyle({
  bg: "brand.100",
  border: "1px solid black",
  color: "white",
  borderRadius: "20px",
  _hover: {
    backgroundColor: "transparent",
    color: "black",
  },
});

const secondary = defineStyle({
  bg: "brand.100",
  borderColor: "brand.100",
  borderWidth: "1px",
  color: "white",
  _hover: {
    backgroundColor: "transparent",
    color: "black",
  },
});

const lg = defineStyle({
  height: "50px",
});

const md = defineStyle({
  height: "40px",
});

export const Button = defineStyleConfig({
  baseStyle: {},
  sizes: {
    lg,
    md,
  },
  variants: {
    brand,
    primary,
    secondary,
  },
  defaultProps: {},
});

export const BUTTON_VARIANTS = {
  BRAND: "brand",
  GHOST: "ghost",
  LINK: "link",
  OUTLINE: "outline",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SOLID: "solid",
  UNSTYLED: "unstyled",
};
