import Image from "next/image";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
} from "@chakra-ui/react";
import logo from "public/brand/logo.png";
import {HEADER_LINKS} from "../header.constants";
import CreateLink from "./createLink";
import ProductsDropdown from "./productsDropdown";

type Props = {isOpen: boolean; onClose: () => void};

const HeaderDrawer = (props: Props) => {
  const {isOpen, onClose} = props;
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay />
      <DrawerContent p={0}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Image alt="logo" src={logo} width={80} height={68} />
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <ProductsDropdown />
            {HEADER_LINKS.map((link, index) => (
              <CreateLink key={index} onClick={onClose} {...link} />
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default HeaderDrawer;
