export const MAIN_ROUTES = {
  ABOUT_US: "/about-us",
  ADVANCE_NETWORK: "/advance-network",
  BAREMETAL: "/baremetal",
  CLOUD_COMPUTE: "/cloud-compute",
  CONTACT_US: "/contact-us",
  COOKIE_POLICIES: "/cookie-policies",
  DATA_CENTER: "/data-center",
  HOME: "/",
  IAAS: "/iaas",
  LOGOUT: "/logout",
  OPTIMIZED_CLOUD_COMPUTE: "/optimized-cloud-compute",
  PAAS: "/paas",
  PARTNERS: "/partners",
  PRICING: "/pricing",
  PRIVACY_POLICIES: "/privacy-policies",
  PROFILE: "/profile",
  SERVICES: "/services",
  TERMS_OF_SERVICE: "/tos",
};

export const IAAS_ROUTES = {
  CLOUD_COMPUTE: "/cloud-compute",
  HYBRID_CLOUD: "/hybrid-cloud",
  KUBERNETES: "/kubernetes-as-a-service",
  LOAD_BALANCERS: "/load-balancers",
  STORAGE: "/storage",
  VIRTUAL_DESKTOP_INFRASTRUCTURE: "/virtual-desktop-infrastructure",
};

export const PAAS_ROUTES = {
  APPLICATION_HOSTING: "/application-hosting",
  APPLICATION_MARKET_PLACE: "/application-marketplace",
  DEVOPS_AUTOMATION: "/devops-automation",
  MANAGED_DATABASE: "/managed-database-as-a-service",
  MANAGED_WORD_PRESS_HOSTING: "/managed-wordpress-hosting",
  WORD_PRESS_AS_A_SERVICE: "/wordpress-as-a-service",
};
