"use client";

import Link from "next/link";
import {Link as ChakraLink, Stack, Heading} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, FEATURES} from "../footer.constants";

const FooterFeatures = () => {
  return (
    <Stack spacing={6}>
      <Heading color="gray.900" variant={HEADING_VARIANTS.SUBTITLE3}>
        {FOOTER_CONSTANTS.FEATURES}
      </Heading>
      <Stack>
        {FEATURES.map((service) => (
          <ChakraLink
            as={Link}
            href={service.path}
            key={service.path}
            target={service.isExternal ? "_blank" : ""}
            _hover={{color: "primary.200", textDecoration: "underline"}}
          >
            {service.name}
          </ChakraLink>
        ))}
      </Stack>
    </Stack>
  );
};

export default FooterFeatures;
