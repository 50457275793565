import {As} from "@chakra-ui/react";
import Link from "next/link";
import {IAAS_ROUTES, MAIN_ROUTES, PAAS_ROUTES} from "@/constants/routes";

export const HEADER_LINKS = [
  {
    name: "Features",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        name: "Data Centers",
        path: MAIN_ROUTES.DATA_CENTER,
      },
      {
        name: "Advanced Network ",
        path: MAIN_ROUTES.ADVANCE_NETWORK,
      },
    ],
  },
  {
    name: "Pricing",
    path: MAIN_ROUTES.PRICING,
  },
  {
    name: "Partners",
    path: MAIN_ROUTES.PARTNERS,
  },
  {
    name: "Company",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        isExternal: true,
        name: "Blog",
        path: "https://medium.com/onemind-services",
      },
      {
        name: "About us",
        path: MAIN_ROUTES.ABOUT_US,
      },
      {
        name: "Contact us",
        path: MAIN_ROUTES.CONTACT_US,
      },
    ],
  },
];

export const USER_LINKS: {name: string; as: As; path: string}[] = [
  {name: "Services", as: Link, path: MAIN_ROUTES.SERVICES},
  {name: "Logout", as: "a", path: MAIN_ROUTES.LOGOUT},
];

export const PRODUCTS = {
  PAAS: [
    {
      name: "Managed Databases",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.MANAGED_DATABASE}`,
    },
    {
      name: "DevOps automation",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.DEVOPS_AUTOMATION}`,
    },
    {
      name: "Application Hosting",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.APPLICATION_HOSTING}`,
    },
    {
      name: "Managed WordPress",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.MANAGED_WORD_PRESS_HOSTING}`,
    },
    {
      name: "WordPress as a Service",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.WORD_PRESS_AS_A_SERVICE}`,
    },
    {
      name: "Application Marketplace",
      path: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.APPLICATION_MARKET_PLACE}`,
    },
  ],
  IAAS: [
    {
      name: "Cloud Compute",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.CLOUD_COMPUTE}`,
    },
    {
      name: "Kubernetes",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.KUBERNETES}`,
    },
    {
      name: "Storage",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.STORAGE}`,
    },
    {
      name: "Hybrid Cloud",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.HYBRID_CLOUD}`,
    },
    {
      name: "Virtual Desktop Infrastructure",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.VIRTUAL_DESKTOP_INFRASTRUCTURE}`,
    },
    {
      name: "Load Balancers",
      path: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.LOAD_BALANCERS}`,
    },
  ],
};
