import {BsTelephone} from "@onemind-services-llc/react-icons-ng/bs";
import {
  // FaFacebookF,
  //  FaInstagram,
  FaLinkedinIn,
  //  FaYoutube
} from "@onemind-services-llc/react-icons-ng/fa";
import {FiMail} from "@onemind-services-llc/react-icons-ng/fi";
import {PiShieldCheck} from "@onemind-services-llc/react-icons-ng/pi";
import {TfiLocationPin} from "@onemind-services-llc/react-icons-ng/tfi";
import {MAIN_ROUTES} from "@/constants/routes";

export const FOOTER_CONSTANTS = {
  BRAND_TEXT: "The Unmetered Cloud",
  CONTACT_US: "Contact Us",
  EDUCATION: "Education",
  FEATURES: "Features",
  ONEMIND_ENTERPRISE: "A Onemind Services Enterprise",
  ONEMIND_PATH: "https://onemindservices.com/",
  SUPPORT: "Support",
  RIGHTS: `© ${new Date().getFullYear()} cloudmydc.com | All rights reserved.`,
};

export const CONTACT_INFO_LIST = [
  {
    icon: FiMail,
    label: "sales@cloudmydc.com",
    path: "mailto:sales@cloudmydc.com",
  },
  {
    icon: PiShieldCheck,
    label: "support@cloudmydc.com",
    path: "mailto:support@cloudmydc.com",
  },
  {icon: BsTelephone, label: "1-866-666-5679", path: "tel:1-866-666-5679"},
  {
    icon: TfiLocationPin,
    label: "11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    path: "https://maps.google.com?q=11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    ext_link: true,
  },
];

export const EDUCATION_OPTIONS = [
  {
    isExternal: true,
    name: "Blog",
    path: "https://medium.com/onemind-services",
  },
];

export const FEATURES = [
  {
    name: "Platform as a Service",
    path: MAIN_ROUTES.PAAS,
  },
  {
    name: "Infrastructure as a Service",
    path: MAIN_ROUTES.IAAS,
  },
  {
    name: "Data Centers",
    path: MAIN_ROUTES.DATA_CENTER,
  },
  {
    name: "Advanced Network ",
    path: MAIN_ROUTES.ADVANCE_NETWORK,
  },
  {
    isExternal: true,
    name: "Blog",
    path: "https://medium.com/onemind-services",
  },
];

export const SUPPORT_OPTIONS = [
  {name: "Home", path: "/"},
  {
    name: "About us",
    path: MAIN_ROUTES.ABOUT_US,
  },
  {
    name: "Contact us",
    path: MAIN_ROUTES.CONTACT_US,
  },
  {
    name: "Partners",
    path: MAIN_ROUTES.PARTNERS,
  },
  {
    name: "Terms Of Services",
    path: MAIN_ROUTES.TERMS_OF_SERVICE,
  },
];

export const POLICIES_OPTIONS = [
  {
    name: "Privacy Policies",
    path: MAIN_ROUTES.PRIVACY_POLICIES,
  },
  {
    name: "Cookie Policies",
    path: MAIN_ROUTES.COOKIE_POLICIES,
  },
];

export const SOCIAL_OPTIONS = [
  // {icon: FaFacebookF, path: "https://www.facebook.com/CloudmylabSV/"},
  // {icon: FaYoutube, path: "https://www.youtube.com/channel/UCfsfSeOsMhDSxW7bjy2gS1Q"},
  {icon: FaLinkedinIn, path: "https://www.linkedin.com/company/cloudmydc/"},
  // {icon: FaInstagram, path: "https://www.instagram.com/cloudmylab/"},
];
