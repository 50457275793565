"use client";
import {Fragment} from "react";
import {useSession, signIn, SessionProvider} from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import {usePathname} from "next/navigation";
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useMediaQuery,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaBars,
  FaChevronDown,
  FaUserCircle,
} from "@onemind-services-llc/react-icons-ng/fa";
import logo from "public/brand/logo-horizontal.png";
import SafeView from "@/components/safeView";
import CreateLink from "./components/createLink";
import HeaderDrawer from "./components/headerDrawer";
import ProductsDropdown from "./components/productsDropdown";
import {HEADER_LINKS, USER_LINKS} from "./header.constants";

const Header = () => {
  const headerBackground = useColorModeValue("white", "gray.800");
  const iconColor = useColorModeValue("gray.600", "gray.200");
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isMobileOrIpad] = useMediaQuery("(max-width: 820px)");

  return (
    <SessionProvider>
      <Fragment>
        {isMobileOrIpad && isOpen && <HeaderDrawer isOpen={isOpen} onClose={onClose} />}
        <Box
          bg={headerBackground}
          boxShadow="0 10px 20px 0 rgba(0,0,0,.1)"
          height={"6rem"}
          left={0}
          position="fixed"
          right={0}
          top={0}
          zIndex="banner"
        >
          <SafeView>
            <Flex alignItems={"center"} height={"6rem"} justifyContent={"space-between"}>
              <Link href={"/"}>
                <Image alt="logo" src={logo} width={200} />
              </Link>
              <HStack display={{base: "flex", md: "none"}} spacing={4}>
                <UserDropdownMobile />
                <IconButton
                  aria-label={"drawer-toggle"}
                  icon={<Icon as={FaBars} color={iconColor} />}
                  onClick={onOpen}
                />
              </HStack>
              <HStack display={{base: "none", md: "flex"}} spacing={6}>
                <ProductsDropdown />
                {HEADER_LINKS.map((link, index) => (
                  <CreateLink key={index} onClick={onClose} {...link} />
                ))}
                <UserDropdown />
              </HStack>
            </Flex>
          </SafeView>
        </Box>
      </Fragment>
    </SessionProvider>
  );
};

export default Header;

const UserDropdown = () => {
  return (
    <Menu>
      <MenuButton>
        <HStack spacing={2}>
          <Icon as={FaUserCircle} fontSize={"24px"} />
          <Icon as={FaChevronDown} fontSize={"12px"} />
        </HStack>
      </MenuButton>
      <UserMenuList />
    </Menu>
  );
};

const UserDropdownMobile = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="User dropdown"
        icon={<Icon as={FaUserCircle} fontSize={"24px"} />}
        _hover={{}}
      ></MenuButton>
      <UserMenuList />
    </Menu>
  );
};

const UserMenuList = () => {
  const pathname = usePathname();
  const {data: session} = useSession();

  return (
    <MenuList>
      {!session ? (
        <MenuItem
          as={"button"}
          onClick={() => signIn("keycloak", {callbackUrl: "/services"})}
          _hover={{color: "primary.200"}}
        >
          Login / Sign Up
        </MenuItem>
      ) : (
        <Fragment>
          {USER_LINKS.map((data, index) => {
            return (
              <MenuItem
                as={data.as}
                color={pathname === data.path ? "primary.200" : ""}
                href={data.path}
                key={index}
                _hover={{color: "primary.200"}}
              >
                {data.name}
              </MenuItem>
            );
          })}
        </Fragment>
      )}
    </MenuList>
  );
};
