"use client";
import Link from "next/link";
import {Heading, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, CONTACT_INFO_LIST} from "../footer.constants";

const FooterContactInfo = () => {
  return (
    <Stack spacing={6}>
      <Heading color="gray.900" variant={HEADING_VARIANTS.SUBTITLE3}>
        {FOOTER_CONSTANTS.CONTACT_US}
      </Heading>
      <Stack>
        {CONTACT_INFO_LIST.map((contact, index) => {
          return (
            <HStack key={index}>
              <Icon as={contact.icon} color="primary.200" fontSize={"18px"} />
              <Text
                as={Link}
                href={contact.path}
                target={contact?.ext_link ? "_blank" : ""}
                _hover={{color: "primary.200", textDecoration: "underline"}}
              >
                {contact.label}
              </Text>
            </HStack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FooterContactInfo;
