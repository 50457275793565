"use client";
import React from "react";
import {usePathname} from "next/navigation";

import {FaChevronDown} from "@onemind-services-llc/react-icons-ng/fa";
import {
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuGroup,
  MenuDivider,
  Text,
} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {MAIN_ROUTES} from "@/constants/routes";
import {PRODUCTS} from "../header.constants";

const ProductsDropdown = () => {
  const pathname = usePathname();

  const isProductsRoute =
    pathname?.includes(MAIN_ROUTES.IAAS) || pathname?.includes(MAIN_ROUTES.IAAS);
  return (
    <Menu>
      <MenuButton>
        <HStack cursor={"pointer"}>
          <Text
            color={isProductsRoute ? "primary.200" : ""}
            lineHeight={1}
            variant={TEXT_VARIANTS.BODY1}
          >
            Products
          </Text>
          <Icon as={FaChevronDown} fontSize="12px" />
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuGroup title="Platform">
          {PRODUCTS.PAAS.map((item, index) => {
            const isActivePathPaas = pathname === item.path;
            return (
              <MenuItem
                as="a"
                color={isActivePathPaas ? "primary.200" : ""}
                href={item.path}
                key={index}
                _hover={{color: "primary.200"}}
              >
                <Text variant={TEXT_VARIANTS.BODY2}>{item.name}</Text>
              </MenuItem>
            );
          })}
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Infrastructure">
          {PRODUCTS.IAAS.map((item, index) => {
            const isActivePathIaas = pathname === item.path;
            return (
              <MenuItem
                as="a"
                color={isActivePathIaas ? "primary.200" : ""}
                href={item.path}
                key={index}
                _hover={{color: "primary.200"}}
              >
                <Text variant={TEXT_VARIANTS.BODY2}>{item.name}</Text>
              </MenuItem>
            );
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default ProductsDropdown;
