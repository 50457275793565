"use client";

import Link from "next/link";
import {HStack, Link as ChakraLink, Stack, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {FOOTER_CONSTANTS, POLICIES_OPTIONS} from "../footer.constants";
const FooterPolicies = () => {
  return (
    <Stack
      alignItems="center"
      direction={{base: "column", sm: "row"}}
      justifyContent={{sm: "space-between"}}
      spacing={4}
    >
      <Text variant={TEXT_VARIANTS.LABEL1}>{FOOTER_CONSTANTS.RIGHTS}</Text>
      <HStack spacing={4}>
        {POLICIES_OPTIONS.map((option) => (
          <ChakraLink
            as={Link}
            href={option.path}
            key={option.path}
            _hover={{color: "primary.200", textDecoration: "underline"}}
          >
            {option.name}
          </ChakraLink>
        ))}
      </HStack>
    </Stack>
  );
};

export default FooterPolicies;
