import {Roboto, Inter} from "next/font/google";

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "700"],
});

const inter = Inter({
  subsets: ["latin"],
  weight: ["400"],
});

export const fonts = {
  heading: roboto.style.fontFamily,
  body: inter.style.fontFamily,
  mono: roboto.style.fontFamily,
};
