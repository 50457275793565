export const colors = {
  brand: {
    100: "#021482",
    200: "#335EEA",
  },
  primary: {
    100: "#cae9ff",
    200: "#1E87F0",
    300: "#9ECFFF",
  },
  text: {
    100: "#8587B2",
  },
};
