"use client";

import {Fragment} from "react";
import Link from "next/link";
import {usePathname} from "next/navigation";
import {Link as ChakraLink, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import HeaderDropdown from "./headerDropdown";

type CreateLinkProps = {
  isExternal?: boolean;
  name: string;
  path?: string;
  onClick?: () => void;
  nestedRoutes?: {isExternal?: boolean; name: string; path: string}[] | [];
};

const CreateLink = (props: CreateLinkProps) => {
  const {isExternal, name, onClick = () => {}, path, nestedRoutes} = props;
  const pathname = usePathname();
  return (
    <Fragment>
      {nestedRoutes ? (
        <HeaderDropdown name={name} onClick={onClick} nestedRoutes={nestedRoutes} />
      ) : (
        <ChakraLink
          as={Link}
          color={pathname === path ? "primary.200" : ""}
          cursor={"pointer"}
          href={path}
          onClick={onClick}
          target={isExternal ? "_blank" : ""}
          _hover={{color: "primary.200"}}
        >
          <Text variant={TEXT_VARIANTS.BODY1}>{name}</Text>
        </ChakraLink>
      )}
    </Fragment>
  );
};

export default CreateLink;
